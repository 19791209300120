import { SET_INPUT as SET_BP_FIND_LOCATION_INPUT } from '@iabbb/business-profile/src/BusinessProfileRedesign/components/DialogFindLocation/FormFindLocation/Typeahead/actionTypes';
import { SET_INPUT as SET_HOME_LOCATION_INPUT } from '@iabbb/shared/containers/Header/LocaleSelector/Modal/ChooseLocationState/HomeLocationTypeahead/actionTypes';
import { SET_INPUT as SET_LOCATE_BBB_INPUT } from '@iabbb/shared/containers/LocateBbbOnMap/LocateBbbTypeahead/actionTypes';
import { SET_CULTURE_ID } from '@iabbb/shared/states/User/actionTypes';
import { produce } from 'immer';

const initialState = {
  bbbDirectoryInput: '',
  bpFindLocationInput: '',
  cultureId: '',
  homeLocation: '',
  isIPGeolocated: 'N',
  locateBbbInput: '',
  geolocation: {},
  isBrowserGeolocated: 'N',
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_LOCATE_BBB_INPUT:
      draft.locateBbbInput = action.input;
      break;
    case SET_HOME_LOCATION_INPUT:
      draft.homeLocation = action.input;
      break;
    case SET_CULTURE_ID:
      draft.cultureId = action.cultureId;
      break;
    case SET_BP_FIND_LOCATION_INPUT:
      draft.bpFindLocationInput = action.input;
      break;
    default:
  }
}, initialState);
