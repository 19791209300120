import { createSelector } from 'reselect';
import { DEFAULT_CULTURE_INFO, DEFAULT_CULTURE_ID } from '@iabbb/utils/culture/constants';
import getIn from '@iabbb/utils/object/getIn';

import CultureData from '@iabbb/utils/culture/allCultureData';

// SELECTORS
const selectCultures = () => CultureData;
const selectUserCultureId = (culture) => getIn(culture, ['user', 'cultureId']);
const selectBaseUrl = () => process.env.NEXT_PUBLIC_BASE_URL;
const selectAccreditationStandardsUrl = () => process.env.NEXT_PUBLIC_ACCREDITATION_STANDARDS_URL;
const selectBbbDirectoryUrl = () => process.env.NEXT_PUBLIC_BBB_DIRECTORY_URL;
const selectBecomeAccreditedUrl = () => process.env.NEXT_PUBLIC_BECOME_ACCREDITED_URL;
const selectGetListedUrl = () => process.env.NEXT_PUBLIC_GET_LISTED_URL;

// GETTERS
// Urls -start
const getAccreditationStandardsUrl = () => selectAccreditationStandardsUrl;
const getBbbDirectoryUrl = () => selectBbbDirectoryUrl;
const getBecomeAccreditedUrl = () => selectBecomeAccreditedUrl;
const getGetListedUrl = () => selectGetListedUrl;
const getBaseUrl = () => selectBaseUrl;
// Urls - end

// Culture -start
const getActiveCulture = () =>
  createSelector([selectCultures, selectUserCultureId], (cultures, userCultureId) =>
    userCultureId ? getIn(cultures, ['byId', userCultureId]) : null,
  );
const getActiveCultureOrDefault = () =>
  createSelector([selectCultures, selectUserCultureId], (cultures, userCultureId) =>
    userCultureId ? getIn(cultures, ['byId', userCultureId]) : getIn(cultures, ['byId', DEFAULT_CULTURE_ID]),
  );
const getCultures = () => selectCultures;
const getActiveCultureInfo = () =>
  createSelector(getActiveCultureOrDefault(), (activeCulture) =>
    activeCulture ? activeCulture.cultureInfo : DEFAULT_CULTURE_INFO,
  );
// Culture - end

export {
  selectAccreditationStandardsUrl,
  getAccreditationStandardsUrl,
  getActiveCulture,
  getActiveCultureOrDefault,
  getBbbDirectoryUrl,
  getBecomeAccreditedUrl,
  getCultures,
  getBaseUrl,
  getActiveCultureInfo,
  getGetListedUrl,
};
